<template>
  <b-card>
      <h3>Comprar del ChatBot</h3>
      <p>Aquí puedes gestionar las Comprar del ChatBot.</p>
  </b-card>

</template>

<script>
export default {
  name: 'Comprar',
}
</script>